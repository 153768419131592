/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Press+Start+2P&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    /* font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 455;
    font-style: normal; */
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
  }
}
ul.exp {
  list-style-type: circle;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.textinbox {
  margin: 3px 0 2px 0;
}

.git {
  color: #005bbb;
  position: absolute;
  bottom: 10px;
}
.bg-container {
  background: #cc2b5e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #753a88,
    #cc2b5e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #753a88,
    #cc2b5e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.header-text {
  --bg-size: 400%;
  --color-one: #eb00ff;
  --color-two: #0047ff;
  font-family: sans-serif;
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: move-bg 6s linear infinite;
}

@keyframes move-bg {
  to {
    background-position: var(--bg-size) 0;
  }
}
